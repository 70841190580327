// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "web_api/user_message.proto" (package "user_message", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { MsgService } from "./user_message";
import type { DeleteUserMessageResp } from "./user_message";
import type { DeleteUserMessageReq } from "./user_message";
import type { ReadUserMessageResp } from "./user_message";
import type { ReadUserMessageReq } from "./user_message";
import type { AddUserMessageResp } from "./user_message";
import type { UserMessage } from "./dto";
import type { GetMsgListReq } from "./user_message";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetMsgListResp } from "./user_message";
import type { Empty } from "../google/protobuf/empty";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service user_message.MsgService
 */
export interface IMsgServiceClient {
    /**
     * @generated from protobuf rpc: GetUserAllMessageList(google.protobuf.Empty) returns (user_message.GetMsgListResp);
     */
    getUserAllMessageList(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetMsgListResp>;
    /**
     * @generated from protobuf rpc: GetUserMessageList(user_message.GetMsgListReq) returns (user_message.GetMsgListResp);
     */
    getUserMessageList(input: GetMsgListReq, options?: RpcOptions): UnaryCall<GetMsgListReq, GetMsgListResp>;
    /**
     * @generated from protobuf rpc: AddUserMessage(dto.UserMessage) returns (user_message.AddUserMessageResp);
     */
    addUserMessage(input: UserMessage, options?: RpcOptions): UnaryCall<UserMessage, AddUserMessageResp>;
    /**
     * @generated from protobuf rpc: ReadUserMessage(user_message.ReadUserMessageReq) returns (user_message.ReadUserMessageResp);
     */
    readUserMessage(input: ReadUserMessageReq, options?: RpcOptions): UnaryCall<ReadUserMessageReq, ReadUserMessageResp>;
    /**
     * @generated from protobuf rpc: DeleteUserMessage(user_message.DeleteUserMessageReq) returns (user_message.DeleteUserMessageResp);
     */
    deleteUserMessage(input: DeleteUserMessageReq, options?: RpcOptions): UnaryCall<DeleteUserMessageReq, DeleteUserMessageResp>;
}
/**
 * @generated from protobuf service user_message.MsgService
 */
export class MsgServiceClient implements IMsgServiceClient, ServiceInfo {
    typeName = MsgService.typeName;
    methods = MsgService.methods;
    options = MsgService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetUserAllMessageList(google.protobuf.Empty) returns (user_message.GetMsgListResp);
     */
    getUserAllMessageList(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetMsgListResp> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetMsgListResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserMessageList(user_message.GetMsgListReq) returns (user_message.GetMsgListResp);
     */
    getUserMessageList(input: GetMsgListReq, options?: RpcOptions): UnaryCall<GetMsgListReq, GetMsgListResp> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMsgListReq, GetMsgListResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddUserMessage(dto.UserMessage) returns (user_message.AddUserMessageResp);
     */
    addUserMessage(input: UserMessage, options?: RpcOptions): UnaryCall<UserMessage, AddUserMessageResp> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserMessage, AddUserMessageResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReadUserMessage(user_message.ReadUserMessageReq) returns (user_message.ReadUserMessageResp);
     */
    readUserMessage(input: ReadUserMessageReq, options?: RpcOptions): UnaryCall<ReadUserMessageReq, ReadUserMessageResp> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReadUserMessageReq, ReadUserMessageResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteUserMessage(user_message.DeleteUserMessageReq) returns (user_message.DeleteUserMessageResp);
     */
    deleteUserMessage(input: DeleteUserMessageReq, options?: RpcOptions): UnaryCall<DeleteUserMessageReq, DeleteUserMessageResp> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteUserMessageReq, DeleteUserMessageResp>("unary", this._transport, method, opt, input);
    }
}

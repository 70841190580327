// Generated code - do not modify
// @ts-nocheck
import { productionClient, localClient, Transport } from '@/utils/api/go_client'

import * as user_message from './user_message'

export class MsgServiceRealClient {
  constructor(private transport: Transport) {}

  async GetUserAllMessageList(
    request: user_message.Empty,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<user_message.GetMsgListResp> {
    return this.transport.request({
      path: '/api/user-message/all',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetUserMessageList(
    request: user_message.GetMsgListReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<user_message.GetMsgListResp> {
    return this.transport.request({
      path: '/api/user-message/page',
      method: 'POST',
      input: request,
      options
    })
  }


  async AddUserMessage(
    request: user_message.UserMessage,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<user_message.AddUserMessageResp> {
    return this.transport.request({
      path: '/user-message/add',
      method: 'POST',
      input: request,
      options
    })
  }


  async ReadUserMessage(
    request: user_message.ReadUserMessageReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<user_message.ReadUserMessageResp> {
    return this.transport.request({
      path: '/api/user-message/read',
      method: 'POST',
      input: request,
      options
    })
  }


  async DeleteUserMessage(
    request: user_message.DeleteUserMessageReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<user_message.DeleteUserMessageResp> {
    return this.transport.request({
      path: '/api/user-message/delete',
      method: 'POST',
      input: request,
      options
    })
  }
}

export const goMsgService = new MsgServiceRealClient(productionClient)

// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "web_api/api_def.proto" (package "web", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ResponseStatus } from "../base";
/**
 * @generated from protobuf message web.UserPreferenceReq
 */
export interface UserPreferenceReq {
    /**
     * @generated from protobuf field: string websiteId = 1;
     */
    websiteId: string;
    /**
     * @generated from protobuf field: web.UserPreferenceReq.PreferenceStatusEnum preferenceStatus = 2;
     */
    preferenceStatus: UserPreferenceReq_PreferenceStatusEnum;
}
/**
 * @generated from protobuf enum web.UserPreferenceReq.PreferenceStatusEnum
 */
export enum UserPreferenceReq_PreferenceStatusEnum {
    /**
     * @generated from protobuf enum value: Unknown = 0;
     */
    Unknown = 0,
    /**
     * @generated from protobuf enum value: Like = 1;
     */
    Like = 1,
    /**
     * @generated from protobuf enum value: RemoveLike = 2;
     */
    RemoveLike = 2,
    /**
     * @generated from protobuf enum value: Dislike = 3;
     */
    Dislike = 3,
    /**
     * @generated from protobuf enum value: RemoveDislike = 4;
     */
    RemoveDislike = 4,
    /**
     * @generated from protobuf enum value: Collect = 5;
     */
    Collect = 5,
    /**
     * @generated from protobuf enum value: RemoveCollect = 6;
     */
    RemoveCollect = 6
}
/**
 * @generated from protobuf message web.UserPreferenceResp
 */
export interface UserPreferenceResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message web.UserPreferenceOption
 */
export interface UserPreferenceOption {
    /**
     * @generated from protobuf field: bool needLike = 1;
     */
    needLike: boolean;
    /**
     * @generated from protobuf field: bool needDislike = 2;
     */
    needDislike: boolean;
    /**
     * @generated from protobuf field: bool needCollect = 3;
     */
    needCollect: boolean;
}
/**
 * @generated from protobuf message web.GetUserPreferenceReq
 */
export interface GetUserPreferenceReq {
    /**
     * @generated from protobuf field: string websiteId = 1;
     */
    websiteId: string;
    /**
     * @generated from protobuf field: web.UserPreferenceOption option = 2;
     */
    option?: UserPreferenceOption;
}
/**
 * @generated from protobuf message web.UserPreference
 */
export interface UserPreference {
    /**
     * @generated from protobuf field: string websiteId = 1;
     */
    websiteId: string;
    /**
     * @generated from protobuf field: int64 likeCount = 2;
     */
    likeCount: bigint;
    /**
     * @generated from protobuf field: int64 dislikeCount = 3;
     */
    dislikeCount: bigint;
    /**
     * @generated from protobuf field: int64 collectCount = 4;
     */
    collectCount: bigint;
}
/**
 * @generated from protobuf message web.GetUserPreferenceResp
 */
export interface GetUserPreferenceResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: web.UserPreference userPreference = 2;
     */
    userPreference?: UserPreference;
}
/**
 * @generated from protobuf message web.UserCollectListReq
 */
export interface UserCollectListReq {
}
/**
 * @generated from protobuf message web.UserCollectListResp
 */
export interface UserCollectListResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: repeated string websiteIds = 2;
     */
    websiteIds: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class UserPreferenceReq$Type extends MessageType<UserPreferenceReq> {
    constructor() {
        super("web.UserPreferenceReq", [
            { no: 1, name: "websiteId", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "api.body": "websiteId" } },
            { no: 2, name: "preferenceStatus", kind: "enum", T: () => ["web.UserPreferenceReq.PreferenceStatusEnum", UserPreferenceReq_PreferenceStatusEnum], options: { "api.body": "preferenceStatus" } }
        ]);
    }
    create(value?: PartialMessage<UserPreferenceReq>): UserPreferenceReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteId = "";
        message.preferenceStatus = 0;
        if (value !== undefined)
            reflectionMergePartial<UserPreferenceReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserPreferenceReq): UserPreferenceReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string websiteId */ 1:
                    message.websiteId = reader.string();
                    break;
                case /* web.UserPreferenceReq.PreferenceStatusEnum preferenceStatus */ 2:
                    message.preferenceStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserPreferenceReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string websiteId = 1; */
        if (message.websiteId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.websiteId);
        /* web.UserPreferenceReq.PreferenceStatusEnum preferenceStatus = 2; */
        if (message.preferenceStatus !== 0)
            writer.tag(2, WireType.Varint).int32(message.preferenceStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web.UserPreferenceReq
 */
export const UserPreferenceReq = new UserPreferenceReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPreferenceResp$Type extends MessageType<UserPreferenceResp> {
    constructor() {
        super("web.UserPreferenceResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<UserPreferenceResp>): UserPreferenceResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UserPreferenceResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserPreferenceResp): UserPreferenceResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserPreferenceResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web.UserPreferenceResp
 */
export const UserPreferenceResp = new UserPreferenceResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPreferenceOption$Type extends MessageType<UserPreferenceOption> {
    constructor() {
        super("web.UserPreferenceOption", [
            { no: 1, name: "needLike", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "needDislike", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "needCollect", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UserPreferenceOption>): UserPreferenceOption {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.needLike = false;
        message.needDislike = false;
        message.needCollect = false;
        if (value !== undefined)
            reflectionMergePartial<UserPreferenceOption>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserPreferenceOption): UserPreferenceOption {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool needLike */ 1:
                    message.needLike = reader.bool();
                    break;
                case /* bool needDislike */ 2:
                    message.needDislike = reader.bool();
                    break;
                case /* bool needCollect */ 3:
                    message.needCollect = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserPreferenceOption, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool needLike = 1; */
        if (message.needLike !== false)
            writer.tag(1, WireType.Varint).bool(message.needLike);
        /* bool needDislike = 2; */
        if (message.needDislike !== false)
            writer.tag(2, WireType.Varint).bool(message.needDislike);
        /* bool needCollect = 3; */
        if (message.needCollect !== false)
            writer.tag(3, WireType.Varint).bool(message.needCollect);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web.UserPreferenceOption
 */
export const UserPreferenceOption = new UserPreferenceOption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPreferenceReq$Type extends MessageType<GetUserPreferenceReq> {
    constructor() {
        super("web.GetUserPreferenceReq", [
            { no: 1, name: "websiteId", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "api.body": "websiteId" } },
            { no: 2, name: "option", kind: "message", T: () => UserPreferenceOption, options: { "api.body": "option" } }
        ]);
    }
    create(value?: PartialMessage<GetUserPreferenceReq>): GetUserPreferenceReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteId = "";
        if (value !== undefined)
            reflectionMergePartial<GetUserPreferenceReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserPreferenceReq): GetUserPreferenceReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string websiteId */ 1:
                    message.websiteId = reader.string();
                    break;
                case /* web.UserPreferenceOption option */ 2:
                    message.option = UserPreferenceOption.internalBinaryRead(reader, reader.uint32(), options, message.option);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserPreferenceReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string websiteId = 1; */
        if (message.websiteId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.websiteId);
        /* web.UserPreferenceOption option = 2; */
        if (message.option)
            UserPreferenceOption.internalBinaryWrite(message.option, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web.GetUserPreferenceReq
 */
export const GetUserPreferenceReq = new GetUserPreferenceReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPreference$Type extends MessageType<UserPreference> {
    constructor() {
        super("web.UserPreference", [
            { no: 1, name: "websiteId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "likeCount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/, options: { "api.go_tag": "json:\"likeCount\"" } },
            { no: 3, name: "dislikeCount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/, options: { "api.go_tag": "json:\"dislikeCount\"" } },
            { no: 4, name: "collectCount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/, options: { "api.go_tag": "json:\"collectCount\"" } }
        ]);
    }
    create(value?: PartialMessage<UserPreference>): UserPreference {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteId = "";
        message.likeCount = 0n;
        message.dislikeCount = 0n;
        message.collectCount = 0n;
        if (value !== undefined)
            reflectionMergePartial<UserPreference>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserPreference): UserPreference {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string websiteId */ 1:
                    message.websiteId = reader.string();
                    break;
                case /* int64 likeCount */ 2:
                    message.likeCount = reader.int64().toBigInt();
                    break;
                case /* int64 dislikeCount */ 3:
                    message.dislikeCount = reader.int64().toBigInt();
                    break;
                case /* int64 collectCount */ 4:
                    message.collectCount = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserPreference, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string websiteId = 1; */
        if (message.websiteId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.websiteId);
        /* int64 likeCount = 2; */
        if (message.likeCount !== 0n)
            writer.tag(2, WireType.Varint).int64(message.likeCount);
        /* int64 dislikeCount = 3; */
        if (message.dislikeCount !== 0n)
            writer.tag(3, WireType.Varint).int64(message.dislikeCount);
        /* int64 collectCount = 4; */
        if (message.collectCount !== 0n)
            writer.tag(4, WireType.Varint).int64(message.collectCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web.UserPreference
 */
export const UserPreference = new UserPreference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPreferenceResp$Type extends MessageType<GetUserPreferenceResp> {
    constructor() {
        super("web.GetUserPreferenceResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "userPreference", kind: "message", T: () => UserPreference }
        ]);
    }
    create(value?: PartialMessage<GetUserPreferenceResp>): GetUserPreferenceResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserPreferenceResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserPreferenceResp): GetUserPreferenceResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* web.UserPreference userPreference */ 2:
                    message.userPreference = UserPreference.internalBinaryRead(reader, reader.uint32(), options, message.userPreference);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserPreferenceResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* web.UserPreference userPreference = 2; */
        if (message.userPreference)
            UserPreference.internalBinaryWrite(message.userPreference, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web.GetUserPreferenceResp
 */
export const GetUserPreferenceResp = new GetUserPreferenceResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserCollectListReq$Type extends MessageType<UserCollectListReq> {
    constructor() {
        super("web.UserCollectListReq", []);
    }
    create(value?: PartialMessage<UserCollectListReq>): UserCollectListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UserCollectListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserCollectListReq): UserCollectListReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UserCollectListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web.UserCollectListReq
 */
export const UserCollectListReq = new UserCollectListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserCollectListResp$Type extends MessageType<UserCollectListResp> {
    constructor() {
        super("web.UserCollectListResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "websiteIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "api.go_tag": "json:\"websiteIds\"" } }
        ]);
    }
    create(value?: PartialMessage<UserCollectListResp>): UserCollectListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteIds = [];
        if (value !== undefined)
            reflectionMergePartial<UserCollectListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserCollectListResp): UserCollectListResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* repeated string websiteIds */ 2:
                    message.websiteIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserCollectListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string websiteIds = 2; */
        for (let i = 0; i < message.websiteIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.websiteIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web.UserCollectListResp
 */
export const UserCollectListResp = new UserCollectListResp$Type();
/**
 * @generated ServiceType for protobuf service web.PreferenceService
 */
export const PreferenceService = new ServiceType("web.PreferenceService", [
    { name: "UserPreference", options: { "api.post": "/api/user/preference" }, I: UserPreferenceReq, O: UserPreferenceResp },
    { name: "GetUserPreference", options: { "api.post": "/api/user/preference/get" }, I: GetUserPreferenceReq, O: GetUserPreferenceResp },
    { name: "UserCollectList", options: { "api.post": "/api/user/collect/list" }, I: UserCollectListReq, O: UserCollectListResp }
]);

// Generated code - do not modify
// @ts-nocheck
import { productionClient, localClient, Transport } from '@/utils/api/go_client'

import * as api_def from './api_def'

export class PreferenceServiceRealClient {
  constructor(private transport: Transport) {}

  async UserPreference(
    request: api_def.UserPreferenceReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<api_def.UserPreferenceResp> {
    return this.transport.request({
      path: '/api/user/preference',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetUserPreference(
    request: api_def.GetUserPreferenceReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<api_def.GetUserPreferenceResp> {
    return this.transport.request({
      path: '/api/user/preference/get',
      method: 'POST',
      input: request,
      options
    })
  }


  async UserCollectList(
    request: api_def.UserCollectListReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<api_def.UserCollectListResp> {
    return this.transport.request({
      path: '/api/user/collect/list',
      method: 'POST',
      input: request,
      options
    })
  }
}

export const goPreferenceService = new PreferenceServiceRealClient(productionClient)

// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "web_api/comment_service.proto" (package "comment", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Comment } from "./dto";
import { Page } from "../base";
import { ResponseStatus } from "../base";
/**
 * @generated from protobuf message comment.AddCommentReq
 */
export interface AddCommentReq {
    /**
     * @generated from protobuf field: string website_id = 1;
     */
    websiteId: string;
    /**
     * @generated from protobuf field: string website_version_id = 2;
     */
    websiteVersionId: string;
    /**
     * @generated from protobuf field: string content = 3;
     */
    content: string;
    /**
     * 回复的评论id，为空则为root评论
     *
     * @generated from protobuf field: string reply_comment_id = 4;
     */
    replyCommentId: string;
    /**
     * 评论分为两级，parent_id为空为root评论，否则为二级评论
     *
     * @generated from protobuf field: string parent_id = 5;
     */
    parentId: string;
}
/**
 * @generated from protobuf message comment.AddCommentResp
 */
export interface AddCommentResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message comment.DeleteCommentReq
 */
export interface DeleteCommentReq {
    /**
     * @generated from protobuf field: string comment_id = 1;
     */
    commentId: string;
}
/**
 * @generated from protobuf message comment.DeleteCommentResp
 */
export interface DeleteCommentResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message comment.GetWebsiteCommentListReq
 */
export interface GetWebsiteCommentListReq {
    /**
     * @generated from protobuf field: string website_id = 1;
     */
    websiteId: string;
    /**
     * @generated from protobuf field: string website_version_id = 2;
     */
    websiteVersionId: string;
    /**
     * @generated from protobuf field: comment.GetWebsiteCommentListReq.SortOption option = 3;
     */
    option: GetWebsiteCommentListReq_SortOption;
    /**
     * @generated from protobuf field: base.Page page = 4;
     */
    page?: Page;
}
/**
 * @generated from protobuf enum comment.GetWebsiteCommentListReq.SortOption
 */
export enum GetWebsiteCommentListReq_SortOption {
    /**
     * @generated from protobuf enum value: Hotness = 0;
     */
    Hotness = 0,
    /**
     * @generated from protobuf enum value: Time = 1;
     */
    Time = 1
}
/**
 * @generated from protobuf message comment.GetWebsiteCommentListResp
 */
export interface GetWebsiteCommentListResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: repeated dto.Comment comments = 2;
     */
    comments: Comment[];
    /**
     * @generated from protobuf field: int32 total = 3;
     */
    total: number;
}
/**
 * @generated from protobuf message comment.GetWebsiteCommentReplyListReq
 */
export interface GetWebsiteCommentReplyListReq {
    /**
     * @generated from protobuf field: string comment_id = 1;
     */
    commentId: string;
    /**
     * @generated from protobuf field: comment.GetWebsiteCommentReplyListReq.SortOption option = 2;
     */
    option: GetWebsiteCommentReplyListReq_SortOption;
    /**
     * @generated from protobuf field: base.Page page = 3;
     */
    page?: Page;
}
/**
 * @generated from protobuf enum comment.GetWebsiteCommentReplyListReq.SortOption
 */
export enum GetWebsiteCommentReplyListReq_SortOption {
    /**
     * @generated from protobuf enum value: Hotness = 0;
     */
    Hotness = 0,
    /**
     * @generated from protobuf enum value: Time = 1;
     */
    Time = 1
}
/**
 * @generated from protobuf message comment.GetWebsiteCommentReplyListResp
 */
export interface GetWebsiteCommentReplyListResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: repeated dto.Comment comments = 2;
     */
    comments: Comment[];
    /**
     * @generated from protobuf field: int32 total = 3;
     */
    total: number;
}
/**
 * @generated from protobuf message comment.UpdateCommentPreferenceReq
 */
export interface UpdateCommentPreferenceReq {
    /**
     * @generated from protobuf field: string comment_id = 1;
     */
    commentId: string;
    /**
     * @generated from protobuf field: comment.UpdateCommentPreferenceReq.PreferenceStatusEnum preference = 2;
     */
    preference: UpdateCommentPreferenceReq_PreferenceStatusEnum;
}
/**
 * @generated from protobuf enum comment.UpdateCommentPreferenceReq.PreferenceStatusEnum
 */
export enum UpdateCommentPreferenceReq_PreferenceStatusEnum {
    /**
     * @generated from protobuf enum value: Unknown = 0;
     */
    Unknown = 0,
    /**
     * @generated from protobuf enum value: Like = 1;
     */
    Like = 1,
    /**
     * @generated from protobuf enum value: RemoveLike = 2;
     */
    RemoveLike = 2,
    /**
     * @generated from protobuf enum value: Dislike = 3;
     */
    Dislike = 3,
    /**
     * @generated from protobuf enum value: RemoveDislike = 4;
     */
    RemoveDislike = 4
}
/**
 * @generated from protobuf message comment.UpdateCommentPreferenceResp
 */
export interface UpdateCommentPreferenceResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
// @generated message type with reflection information, may provide speed optimized methods
class AddCommentReq$Type extends MessageType<AddCommentReq> {
    constructor() {
        super("comment.AddCommentReq", [
            { no: 1, name: "website_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "website_version_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "reply_comment_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "parent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddCommentReq>): AddCommentReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteId = "";
        message.websiteVersionId = "";
        message.content = "";
        message.replyCommentId = "";
        message.parentId = "";
        if (value !== undefined)
            reflectionMergePartial<AddCommentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddCommentReq): AddCommentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string website_id */ 1:
                    message.websiteId = reader.string();
                    break;
                case /* string website_version_id */ 2:
                    message.websiteVersionId = reader.string();
                    break;
                case /* string content */ 3:
                    message.content = reader.string();
                    break;
                case /* string reply_comment_id */ 4:
                    message.replyCommentId = reader.string();
                    break;
                case /* string parent_id */ 5:
                    message.parentId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddCommentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string website_id = 1; */
        if (message.websiteId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.websiteId);
        /* string website_version_id = 2; */
        if (message.websiteVersionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.websiteVersionId);
        /* string content = 3; */
        if (message.content !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.content);
        /* string reply_comment_id = 4; */
        if (message.replyCommentId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.replyCommentId);
        /* string parent_id = 5; */
        if (message.parentId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.parentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message comment.AddCommentReq
 */
export const AddCommentReq = new AddCommentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddCommentResp$Type extends MessageType<AddCommentResp> {
    constructor() {
        super("comment.AddCommentResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<AddCommentResp>): AddCommentResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AddCommentResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddCommentResp): AddCommentResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddCommentResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message comment.AddCommentResp
 */
export const AddCommentResp = new AddCommentResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteCommentReq$Type extends MessageType<DeleteCommentReq> {
    constructor() {
        super("comment.DeleteCommentReq", [
            { no: 1, name: "comment_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteCommentReq>): DeleteCommentReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.commentId = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteCommentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteCommentReq): DeleteCommentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string comment_id */ 1:
                    message.commentId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteCommentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string comment_id = 1; */
        if (message.commentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.commentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message comment.DeleteCommentReq
 */
export const DeleteCommentReq = new DeleteCommentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteCommentResp$Type extends MessageType<DeleteCommentResp> {
    constructor() {
        super("comment.DeleteCommentResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<DeleteCommentResp>): DeleteCommentResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteCommentResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteCommentResp): DeleteCommentResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteCommentResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message comment.DeleteCommentResp
 */
export const DeleteCommentResp = new DeleteCommentResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteCommentListReq$Type extends MessageType<GetWebsiteCommentListReq> {
    constructor() {
        super("comment.GetWebsiteCommentListReq", [
            { no: 1, name: "website_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "website_version_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "option", kind: "enum", T: () => ["comment.GetWebsiteCommentListReq.SortOption", GetWebsiteCommentListReq_SortOption] },
            { no: 4, name: "page", kind: "message", T: () => Page }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteCommentListReq>): GetWebsiteCommentListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteId = "";
        message.websiteVersionId = "";
        message.option = 0;
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteCommentListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteCommentListReq): GetWebsiteCommentListReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string website_id */ 1:
                    message.websiteId = reader.string();
                    break;
                case /* string website_version_id */ 2:
                    message.websiteVersionId = reader.string();
                    break;
                case /* comment.GetWebsiteCommentListReq.SortOption option */ 3:
                    message.option = reader.int32();
                    break;
                case /* base.Page page */ 4:
                    message.page = Page.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteCommentListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string website_id = 1; */
        if (message.websiteId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.websiteId);
        /* string website_version_id = 2; */
        if (message.websiteVersionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.websiteVersionId);
        /* comment.GetWebsiteCommentListReq.SortOption option = 3; */
        if (message.option !== 0)
            writer.tag(3, WireType.Varint).int32(message.option);
        /* base.Page page = 4; */
        if (message.page)
            Page.internalBinaryWrite(message.page, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message comment.GetWebsiteCommentListReq
 */
export const GetWebsiteCommentListReq = new GetWebsiteCommentListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteCommentListResp$Type extends MessageType<GetWebsiteCommentListResp> {
    constructor() {
        super("comment.GetWebsiteCommentListResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "comments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Comment },
            { no: 3, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteCommentListResp>): GetWebsiteCommentListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.comments = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteCommentListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteCommentListResp): GetWebsiteCommentListResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* repeated dto.Comment comments */ 2:
                    message.comments.push(Comment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total */ 3:
                    message.total = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteCommentListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated dto.Comment comments = 2; */
        for (let i = 0; i < message.comments.length; i++)
            Comment.internalBinaryWrite(message.comments[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 total = 3; */
        if (message.total !== 0)
            writer.tag(3, WireType.Varint).int32(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message comment.GetWebsiteCommentListResp
 */
export const GetWebsiteCommentListResp = new GetWebsiteCommentListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteCommentReplyListReq$Type extends MessageType<GetWebsiteCommentReplyListReq> {
    constructor() {
        super("comment.GetWebsiteCommentReplyListReq", [
            { no: 1, name: "comment_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "option", kind: "enum", T: () => ["comment.GetWebsiteCommentReplyListReq.SortOption", GetWebsiteCommentReplyListReq_SortOption] },
            { no: 3, name: "page", kind: "message", T: () => Page }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteCommentReplyListReq>): GetWebsiteCommentReplyListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.commentId = "";
        message.option = 0;
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteCommentReplyListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteCommentReplyListReq): GetWebsiteCommentReplyListReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string comment_id */ 1:
                    message.commentId = reader.string();
                    break;
                case /* comment.GetWebsiteCommentReplyListReq.SortOption option */ 2:
                    message.option = reader.int32();
                    break;
                case /* base.Page page */ 3:
                    message.page = Page.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteCommentReplyListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string comment_id = 1; */
        if (message.commentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.commentId);
        /* comment.GetWebsiteCommentReplyListReq.SortOption option = 2; */
        if (message.option !== 0)
            writer.tag(2, WireType.Varint).int32(message.option);
        /* base.Page page = 3; */
        if (message.page)
            Page.internalBinaryWrite(message.page, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message comment.GetWebsiteCommentReplyListReq
 */
export const GetWebsiteCommentReplyListReq = new GetWebsiteCommentReplyListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteCommentReplyListResp$Type extends MessageType<GetWebsiteCommentReplyListResp> {
    constructor() {
        super("comment.GetWebsiteCommentReplyListResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "comments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Comment },
            { no: 3, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteCommentReplyListResp>): GetWebsiteCommentReplyListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.comments = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteCommentReplyListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteCommentReplyListResp): GetWebsiteCommentReplyListResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* repeated dto.Comment comments */ 2:
                    message.comments.push(Comment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total */ 3:
                    message.total = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteCommentReplyListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated dto.Comment comments = 2; */
        for (let i = 0; i < message.comments.length; i++)
            Comment.internalBinaryWrite(message.comments[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 total = 3; */
        if (message.total !== 0)
            writer.tag(3, WireType.Varint).int32(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message comment.GetWebsiteCommentReplyListResp
 */
export const GetWebsiteCommentReplyListResp = new GetWebsiteCommentReplyListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCommentPreferenceReq$Type extends MessageType<UpdateCommentPreferenceReq> {
    constructor() {
        super("comment.UpdateCommentPreferenceReq", [
            { no: 1, name: "comment_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "preference", kind: "enum", T: () => ["comment.UpdateCommentPreferenceReq.PreferenceStatusEnum", UpdateCommentPreferenceReq_PreferenceStatusEnum] }
        ]);
    }
    create(value?: PartialMessage<UpdateCommentPreferenceReq>): UpdateCommentPreferenceReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.commentId = "";
        message.preference = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateCommentPreferenceReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCommentPreferenceReq): UpdateCommentPreferenceReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string comment_id */ 1:
                    message.commentId = reader.string();
                    break;
                case /* comment.UpdateCommentPreferenceReq.PreferenceStatusEnum preference */ 2:
                    message.preference = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCommentPreferenceReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string comment_id = 1; */
        if (message.commentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.commentId);
        /* comment.UpdateCommentPreferenceReq.PreferenceStatusEnum preference = 2; */
        if (message.preference !== 0)
            writer.tag(2, WireType.Varint).int32(message.preference);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message comment.UpdateCommentPreferenceReq
 */
export const UpdateCommentPreferenceReq = new UpdateCommentPreferenceReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCommentPreferenceResp$Type extends MessageType<UpdateCommentPreferenceResp> {
    constructor() {
        super("comment.UpdateCommentPreferenceResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<UpdateCommentPreferenceResp>): UpdateCommentPreferenceResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateCommentPreferenceResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCommentPreferenceResp): UpdateCommentPreferenceResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCommentPreferenceResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message comment.UpdateCommentPreferenceResp
 */
export const UpdateCommentPreferenceResp = new UpdateCommentPreferenceResp$Type();
/**
 * @generated ServiceType for protobuf service comment.CommentService
 */
export const CommentService = new ServiceType("comment.CommentService", [
    { name: "AddComment", options: { "api.post": "/api/comment/add" }, I: AddCommentReq, O: AddCommentResp },
    { name: "DeleteComment", options: { "api.post": "/api/comment/delete" }, I: DeleteCommentReq, O: DeleteCommentResp },
    { name: "GetWebsiteCommentList", options: { "api.post": "/api/comment/list" }, I: GetWebsiteCommentListReq, O: GetWebsiteCommentListResp },
    { name: "GetWebsiteCommentReplyList", options: { "api.post": "/api/comment/list/reply" }, I: GetWebsiteCommentReplyListReq, O: GetWebsiteCommentReplyListResp },
    { name: "UpdateCommentPreference", options: { "api.post": "/api/comment/preference/update" }, I: UpdateCommentPreferenceReq, O: UpdateCommentPreferenceResp }
]);

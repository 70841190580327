// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "web_api/website_service.proto" (package "website", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Website } from "./dto";
import { ResponseStatus } from "../base";
import { Page } from "../base";
/**
 * @generated from protobuf message website.GetWebsiteListReq
 */
export interface GetWebsiteListReq {
    /**
     * @generated from protobuf field: repeated string website_ids = 1;
     */
    websiteIds: string[];
    /**
     * @generated from protobuf field: website.WebsiteListType list_type = 2;
     */
    listType: WebsiteListType;
    /**
     * @generated from protobuf field: base.Page page = 3;
     */
    page?: Page;
    /**
     * @generated from protobuf field: string keyword = 4;
     */
    keyword: string;
}
/**
 * @generated from protobuf message website.GetWebsiteListResp
 */
export interface GetWebsiteListResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: website.GetWebsiteListRespData data = 2;
     */
    data?: GetWebsiteListRespData;
}
/**
 * @generated from protobuf message website.GetWebsiteListRespData
 */
export interface GetWebsiteListRespData {
    /**
     * @generated from protobuf field: repeated dto.Website websites = 1;
     */
    websites: Website[];
    /**
     * @generated from protobuf field: int64 total = 2;
     */
    total: bigint;
}
/**
 * @generated from protobuf message website.GetRelativeWebsiteReq
 */
export interface GetRelativeWebsiteReq {
    /**
     * @generated from protobuf field: string website_id = 1;
     */
    websiteId: string;
    /**
     * @generated from protobuf field: repeated string tags = 2;
     */
    tags: string[];
}
/**
 * @generated from protobuf message website.GetRelativeWebsiteResp
 */
export interface GetRelativeWebsiteResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: website.GetRelativeWebsiteRespData data = 2;
     */
    data?: GetRelativeWebsiteRespData;
}
/**
 * @generated from protobuf message website.GetRelativeWebsiteRespData
 */
export interface GetRelativeWebsiteRespData {
    /**
     * @generated from protobuf field: repeated dto.Website websites = 1;
     */
    websites: Website[];
    /**
     * @generated from protobuf field: int64 total = 2;
     */
    total: bigint;
}
/**
 * @generated from protobuf message website.GetWebsiteUserPreferenceReq
 */
export interface GetWebsiteUserPreferenceReq {
    /**
     * @generated from protobuf field: string website_id = 1;
     */
    websiteId: string;
}
/**
 * @generated from protobuf message website.GetWebsiteUserPreferenceResp
 */
export interface GetWebsiteUserPreferenceResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: website.GetWebsiteUserPreferenceRespData data = 2;
     */
    data?: GetWebsiteUserPreferenceRespData;
}
/**
 * @generated from protobuf message website.GetWebsiteUserPreferenceRespData
 */
export interface GetWebsiteUserPreferenceRespData {
    /**
     * @generated from protobuf field: bool isLike = 1;
     */
    isLike: boolean;
    /**
     * @generated from protobuf field: bool isDislike = 2;
     */
    isDislike: boolean;
    /**
     * @generated from protobuf field: bool isCollect = 3;
     */
    isCollect: boolean;
}
/**
 * @generated from protobuf message website.UpdateUserPreferenceReq
 */
export interface UpdateUserPreferenceReq {
    /**
     * @generated from protobuf field: string website_id = 1;
     */
    websiteId: string;
    /**
     * @generated from protobuf field: string website_version_id = 2;
     */
    websiteVersionId: string;
    /**
     * @generated from protobuf field: website.UpdateUserPreferenceReq.PreferenceStatusEnum preference = 3;
     */
    preference: UpdateUserPreferenceReq_PreferenceStatusEnum;
}
/**
 * @generated from protobuf enum website.UpdateUserPreferenceReq.PreferenceStatusEnum
 */
export enum UpdateUserPreferenceReq_PreferenceStatusEnum {
    /**
     * @generated from protobuf enum value: Unknown = 0;
     */
    Unknown = 0,
    /**
     * @generated from protobuf enum value: Like = 1;
     */
    Like = 1,
    /**
     * @generated from protobuf enum value: RemoveLike = 2;
     */
    RemoveLike = 2,
    /**
     * @generated from protobuf enum value: Dislike = 3;
     */
    Dislike = 3,
    /**
     * @generated from protobuf enum value: RemoveDislike = 4;
     */
    RemoveDislike = 4,
    /**
     * @generated from protobuf enum value: Collect = 5;
     */
    Collect = 5,
    /**
     * @generated from protobuf enum value: RemoveCollect = 6;
     */
    RemoveCollect = 6
}
/**
 * @generated from protobuf message website.UpdateUserPreferenceResp
 */
export interface UpdateUserPreferenceResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message website.GetWebsiteMetricCountReq
 */
export interface GetWebsiteMetricCountReq {
    /**
     * @generated from protobuf field: string website_id = 1;
     */
    websiteId: string;
}
/**
 * @generated from protobuf message website.GetWebsiteMetricCountRespData
 */
export interface GetWebsiteMetricCountRespData {
    /**
     * @generated from protobuf field: int32 likes = 1;
     */
    likes: number;
    /**
     * @generated from protobuf field: int32 dislikes = 2;
     */
    dislikes: number;
    /**
     * @generated from protobuf field: int32 collects = 3;
     */
    collects: number;
    /**
     * @generated from protobuf field: int32 works = 4;
     */
    works: number;
    /**
     * @generated from protobuf field: int32 not_works = 5;
     */
    notWorks: number;
    /**
     * @generated from protobuf field: int32 comments = 6;
     */
    comments: number;
}
/**
 * @generated from protobuf message website.GetWebsiteMetricCountResp
 */
export interface GetWebsiteMetricCountResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: website.GetWebsiteMetricCountRespData data = 2;
     */
    data?: GetWebsiteMetricCountRespData;
}
/**
 * @generated from protobuf message website.EvalWebsiteReq
 */
export interface EvalWebsiteReq {
    /**
     * @generated from protobuf field: string website_id = 1;
     */
    websiteId: string;
    /**
     * @generated from protobuf field: string website_version_id = 2;
     */
    websiteVersionId: string;
    /**
     * @generated from protobuf field: website.EvalWebsiteReq.EvalTypeEnum eval_type = 3;
     */
    evalType: EvalWebsiteReq_EvalTypeEnum;
}
/**
 * @generated from protobuf enum website.EvalWebsiteReq.EvalTypeEnum
 */
export enum EvalWebsiteReq_EvalTypeEnum {
    /**
     * @generated from protobuf enum value: Works = 0;
     */
    Works = 0,
    /**
     * @generated from protobuf enum value: NotWorks = 1;
     */
    NotWorks = 1
}
/**
 * @generated from protobuf message website.EvalWebsiteResp
 */
export interface EvalWebsiteResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf enum website.WebsiteListType
 */
export enum WebsiteListType {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: FEATURED = 1;
     */
    FEATURED = 1,
    /**
     * @generated from protobuf enum value: LATEST = 2;
     */
    LATEST = 2,
    /**
     * @generated from protobuf enum value: RECOMMEND = 3;
     */
    RECOMMEND = 3,
    /**
     * @generated from protobuf enum value: FOLLOWING = 4;
     */
    FOLLOWING = 4,
    /**
     * @generated from protobuf enum value: MINE = 5;
     */
    MINE = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteListReq$Type extends MessageType<GetWebsiteListReq> {
    constructor() {
        super("website.GetWebsiteListReq", [
            { no: 1, name: "website_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "list_type", kind: "enum", T: () => ["website.WebsiteListType", WebsiteListType] },
            { no: 3, name: "page", kind: "message", T: () => Page },
            { no: 4, name: "keyword", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteListReq>): GetWebsiteListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteIds = [];
        message.listType = 0;
        message.keyword = "";
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteListReq): GetWebsiteListReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string website_ids */ 1:
                    message.websiteIds.push(reader.string());
                    break;
                case /* website.WebsiteListType list_type */ 2:
                    message.listType = reader.int32();
                    break;
                case /* base.Page page */ 3:
                    message.page = Page.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                case /* string keyword */ 4:
                    message.keyword = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string website_ids = 1; */
        for (let i = 0; i < message.websiteIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.websiteIds[i]);
        /* website.WebsiteListType list_type = 2; */
        if (message.listType !== 0)
            writer.tag(2, WireType.Varint).int32(message.listType);
        /* base.Page page = 3; */
        if (message.page)
            Page.internalBinaryWrite(message.page, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string keyword = 4; */
        if (message.keyword !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.keyword);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.GetWebsiteListReq
 */
export const GetWebsiteListReq = new GetWebsiteListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteListResp$Type extends MessageType<GetWebsiteListResp> {
    constructor() {
        super("website.GetWebsiteListResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "data", kind: "message", T: () => GetWebsiteListRespData }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteListResp>): GetWebsiteListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteListResp): GetWebsiteListResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* website.GetWebsiteListRespData data */ 2:
                    message.data = GetWebsiteListRespData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* website.GetWebsiteListRespData data = 2; */
        if (message.data)
            GetWebsiteListRespData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.GetWebsiteListResp
 */
export const GetWebsiteListResp = new GetWebsiteListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteListRespData$Type extends MessageType<GetWebsiteListRespData> {
    constructor() {
        super("website.GetWebsiteListRespData", [
            { no: 1, name: "websites", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Website },
            { no: 2, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteListRespData>): GetWebsiteListRespData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websites = [];
        message.total = 0n;
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteListRespData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteListRespData): GetWebsiteListRespData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated dto.Website websites */ 1:
                    message.websites.push(Website.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total */ 2:
                    message.total = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteListRespData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated dto.Website websites = 1; */
        for (let i = 0; i < message.websites.length; i++)
            Website.internalBinaryWrite(message.websites[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total = 2; */
        if (message.total !== 0n)
            writer.tag(2, WireType.Varint).int64(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.GetWebsiteListRespData
 */
export const GetWebsiteListRespData = new GetWebsiteListRespData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRelativeWebsiteReq$Type extends MessageType<GetRelativeWebsiteReq> {
    constructor() {
        super("website.GetRelativeWebsiteReq", [
            { no: 1, name: "website_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRelativeWebsiteReq>): GetRelativeWebsiteReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteId = "";
        message.tags = [];
        if (value !== undefined)
            reflectionMergePartial<GetRelativeWebsiteReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRelativeWebsiteReq): GetRelativeWebsiteReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string website_id */ 1:
                    message.websiteId = reader.string();
                    break;
                case /* repeated string tags */ 2:
                    message.tags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRelativeWebsiteReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string website_id = 1; */
        if (message.websiteId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.websiteId);
        /* repeated string tags = 2; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.tags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.GetRelativeWebsiteReq
 */
export const GetRelativeWebsiteReq = new GetRelativeWebsiteReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRelativeWebsiteResp$Type extends MessageType<GetRelativeWebsiteResp> {
    constructor() {
        super("website.GetRelativeWebsiteResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "data", kind: "message", T: () => GetRelativeWebsiteRespData }
        ]);
    }
    create(value?: PartialMessage<GetRelativeWebsiteResp>): GetRelativeWebsiteResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetRelativeWebsiteResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRelativeWebsiteResp): GetRelativeWebsiteResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* website.GetRelativeWebsiteRespData data */ 2:
                    message.data = GetRelativeWebsiteRespData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRelativeWebsiteResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* website.GetRelativeWebsiteRespData data = 2; */
        if (message.data)
            GetRelativeWebsiteRespData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.GetRelativeWebsiteResp
 */
export const GetRelativeWebsiteResp = new GetRelativeWebsiteResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRelativeWebsiteRespData$Type extends MessageType<GetRelativeWebsiteRespData> {
    constructor() {
        super("website.GetRelativeWebsiteRespData", [
            { no: 1, name: "websites", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Website },
            { no: 2, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<GetRelativeWebsiteRespData>): GetRelativeWebsiteRespData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websites = [];
        message.total = 0n;
        if (value !== undefined)
            reflectionMergePartial<GetRelativeWebsiteRespData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRelativeWebsiteRespData): GetRelativeWebsiteRespData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated dto.Website websites */ 1:
                    message.websites.push(Website.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total */ 2:
                    message.total = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRelativeWebsiteRespData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated dto.Website websites = 1; */
        for (let i = 0; i < message.websites.length; i++)
            Website.internalBinaryWrite(message.websites[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total = 2; */
        if (message.total !== 0n)
            writer.tag(2, WireType.Varint).int64(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.GetRelativeWebsiteRespData
 */
export const GetRelativeWebsiteRespData = new GetRelativeWebsiteRespData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteUserPreferenceReq$Type extends MessageType<GetWebsiteUserPreferenceReq> {
    constructor() {
        super("website.GetWebsiteUserPreferenceReq", [
            { no: 1, name: "website_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteUserPreferenceReq>): GetWebsiteUserPreferenceReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteId = "";
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteUserPreferenceReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteUserPreferenceReq): GetWebsiteUserPreferenceReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string website_id */ 1:
                    message.websiteId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteUserPreferenceReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string website_id = 1; */
        if (message.websiteId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.websiteId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.GetWebsiteUserPreferenceReq
 */
export const GetWebsiteUserPreferenceReq = new GetWebsiteUserPreferenceReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteUserPreferenceResp$Type extends MessageType<GetWebsiteUserPreferenceResp> {
    constructor() {
        super("website.GetWebsiteUserPreferenceResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "data", kind: "message", T: () => GetWebsiteUserPreferenceRespData }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteUserPreferenceResp>): GetWebsiteUserPreferenceResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteUserPreferenceResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteUserPreferenceResp): GetWebsiteUserPreferenceResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* website.GetWebsiteUserPreferenceRespData data */ 2:
                    message.data = GetWebsiteUserPreferenceRespData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteUserPreferenceResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* website.GetWebsiteUserPreferenceRespData data = 2; */
        if (message.data)
            GetWebsiteUserPreferenceRespData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.GetWebsiteUserPreferenceResp
 */
export const GetWebsiteUserPreferenceResp = new GetWebsiteUserPreferenceResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteUserPreferenceRespData$Type extends MessageType<GetWebsiteUserPreferenceRespData> {
    constructor() {
        super("website.GetWebsiteUserPreferenceRespData", [
            { no: 1, name: "isLike", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "isDislike", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "isCollect", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteUserPreferenceRespData>): GetWebsiteUserPreferenceRespData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isLike = false;
        message.isDislike = false;
        message.isCollect = false;
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteUserPreferenceRespData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteUserPreferenceRespData): GetWebsiteUserPreferenceRespData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isLike */ 1:
                    message.isLike = reader.bool();
                    break;
                case /* bool isDislike */ 2:
                    message.isDislike = reader.bool();
                    break;
                case /* bool isCollect */ 3:
                    message.isCollect = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteUserPreferenceRespData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isLike = 1; */
        if (message.isLike !== false)
            writer.tag(1, WireType.Varint).bool(message.isLike);
        /* bool isDislike = 2; */
        if (message.isDislike !== false)
            writer.tag(2, WireType.Varint).bool(message.isDislike);
        /* bool isCollect = 3; */
        if (message.isCollect !== false)
            writer.tag(3, WireType.Varint).bool(message.isCollect);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.GetWebsiteUserPreferenceRespData
 */
export const GetWebsiteUserPreferenceRespData = new GetWebsiteUserPreferenceRespData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserPreferenceReq$Type extends MessageType<UpdateUserPreferenceReq> {
    constructor() {
        super("website.UpdateUserPreferenceReq", [
            { no: 1, name: "website_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "website_version_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "preference", kind: "enum", T: () => ["website.UpdateUserPreferenceReq.PreferenceStatusEnum", UpdateUserPreferenceReq_PreferenceStatusEnum] }
        ]);
    }
    create(value?: PartialMessage<UpdateUserPreferenceReq>): UpdateUserPreferenceReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteId = "";
        message.websiteVersionId = "";
        message.preference = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateUserPreferenceReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserPreferenceReq): UpdateUserPreferenceReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string website_id */ 1:
                    message.websiteId = reader.string();
                    break;
                case /* string website_version_id */ 2:
                    message.websiteVersionId = reader.string();
                    break;
                case /* website.UpdateUserPreferenceReq.PreferenceStatusEnum preference */ 3:
                    message.preference = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserPreferenceReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string website_id = 1; */
        if (message.websiteId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.websiteId);
        /* string website_version_id = 2; */
        if (message.websiteVersionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.websiteVersionId);
        /* website.UpdateUserPreferenceReq.PreferenceStatusEnum preference = 3; */
        if (message.preference !== 0)
            writer.tag(3, WireType.Varint).int32(message.preference);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.UpdateUserPreferenceReq
 */
export const UpdateUserPreferenceReq = new UpdateUserPreferenceReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserPreferenceResp$Type extends MessageType<UpdateUserPreferenceResp> {
    constructor() {
        super("website.UpdateUserPreferenceResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<UpdateUserPreferenceResp>): UpdateUserPreferenceResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateUserPreferenceResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserPreferenceResp): UpdateUserPreferenceResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserPreferenceResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.UpdateUserPreferenceResp
 */
export const UpdateUserPreferenceResp = new UpdateUserPreferenceResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteMetricCountReq$Type extends MessageType<GetWebsiteMetricCountReq> {
    constructor() {
        super("website.GetWebsiteMetricCountReq", [
            { no: 1, name: "website_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteMetricCountReq>): GetWebsiteMetricCountReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteId = "";
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteMetricCountReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteMetricCountReq): GetWebsiteMetricCountReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string website_id */ 1:
                    message.websiteId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteMetricCountReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string website_id = 1; */
        if (message.websiteId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.websiteId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.GetWebsiteMetricCountReq
 */
export const GetWebsiteMetricCountReq = new GetWebsiteMetricCountReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteMetricCountRespData$Type extends MessageType<GetWebsiteMetricCountRespData> {
    constructor() {
        super("website.GetWebsiteMetricCountRespData", [
            { no: 1, name: "likes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "dislikes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "collects", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "works", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "not_works", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "comments", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteMetricCountRespData>): GetWebsiteMetricCountRespData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.likes = 0;
        message.dislikes = 0;
        message.collects = 0;
        message.works = 0;
        message.notWorks = 0;
        message.comments = 0;
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteMetricCountRespData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteMetricCountRespData): GetWebsiteMetricCountRespData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 likes */ 1:
                    message.likes = reader.int32();
                    break;
                case /* int32 dislikes */ 2:
                    message.dislikes = reader.int32();
                    break;
                case /* int32 collects */ 3:
                    message.collects = reader.int32();
                    break;
                case /* int32 works */ 4:
                    message.works = reader.int32();
                    break;
                case /* int32 not_works */ 5:
                    message.notWorks = reader.int32();
                    break;
                case /* int32 comments */ 6:
                    message.comments = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteMetricCountRespData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 likes = 1; */
        if (message.likes !== 0)
            writer.tag(1, WireType.Varint).int32(message.likes);
        /* int32 dislikes = 2; */
        if (message.dislikes !== 0)
            writer.tag(2, WireType.Varint).int32(message.dislikes);
        /* int32 collects = 3; */
        if (message.collects !== 0)
            writer.tag(3, WireType.Varint).int32(message.collects);
        /* int32 works = 4; */
        if (message.works !== 0)
            writer.tag(4, WireType.Varint).int32(message.works);
        /* int32 not_works = 5; */
        if (message.notWorks !== 0)
            writer.tag(5, WireType.Varint).int32(message.notWorks);
        /* int32 comments = 6; */
        if (message.comments !== 0)
            writer.tag(6, WireType.Varint).int32(message.comments);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.GetWebsiteMetricCountRespData
 */
export const GetWebsiteMetricCountRespData = new GetWebsiteMetricCountRespData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteMetricCountResp$Type extends MessageType<GetWebsiteMetricCountResp> {
    constructor() {
        super("website.GetWebsiteMetricCountResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "data", kind: "message", T: () => GetWebsiteMetricCountRespData }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteMetricCountResp>): GetWebsiteMetricCountResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteMetricCountResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteMetricCountResp): GetWebsiteMetricCountResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* website.GetWebsiteMetricCountRespData data */ 2:
                    message.data = GetWebsiteMetricCountRespData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteMetricCountResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* website.GetWebsiteMetricCountRespData data = 2; */
        if (message.data)
            GetWebsiteMetricCountRespData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.GetWebsiteMetricCountResp
 */
export const GetWebsiteMetricCountResp = new GetWebsiteMetricCountResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EvalWebsiteReq$Type extends MessageType<EvalWebsiteReq> {
    constructor() {
        super("website.EvalWebsiteReq", [
            { no: 1, name: "website_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "website_version_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "eval_type", kind: "enum", T: () => ["website.EvalWebsiteReq.EvalTypeEnum", EvalWebsiteReq_EvalTypeEnum] }
        ]);
    }
    create(value?: PartialMessage<EvalWebsiteReq>): EvalWebsiteReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteId = "";
        message.websiteVersionId = "";
        message.evalType = 0;
        if (value !== undefined)
            reflectionMergePartial<EvalWebsiteReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EvalWebsiteReq): EvalWebsiteReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string website_id */ 1:
                    message.websiteId = reader.string();
                    break;
                case /* string website_version_id */ 2:
                    message.websiteVersionId = reader.string();
                    break;
                case /* website.EvalWebsiteReq.EvalTypeEnum eval_type */ 3:
                    message.evalType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EvalWebsiteReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string website_id = 1; */
        if (message.websiteId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.websiteId);
        /* string website_version_id = 2; */
        if (message.websiteVersionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.websiteVersionId);
        /* website.EvalWebsiteReq.EvalTypeEnum eval_type = 3; */
        if (message.evalType !== 0)
            writer.tag(3, WireType.Varint).int32(message.evalType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.EvalWebsiteReq
 */
export const EvalWebsiteReq = new EvalWebsiteReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EvalWebsiteResp$Type extends MessageType<EvalWebsiteResp> {
    constructor() {
        super("website.EvalWebsiteResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<EvalWebsiteResp>): EvalWebsiteResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EvalWebsiteResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EvalWebsiteResp): EvalWebsiteResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EvalWebsiteResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message website.EvalWebsiteResp
 */
export const EvalWebsiteResp = new EvalWebsiteResp$Type();
/**
 * @generated ServiceType for protobuf service website.WebsiteService
 */
export const WebsiteService = new ServiceType("website.WebsiteService", [
    { name: "GetWebsiteList", options: { "api.post": "/api/website/list" }, I: GetWebsiteListReq, O: GetWebsiteListResp },
    { name: "GetRelativeWebsiteList", options: { "api.post": "/api/website/list/relative" }, I: GetRelativeWebsiteReq, O: GetRelativeWebsiteResp },
    { name: "GetWebsiteUserPreference", options: { "api.post": "/api/website/user/preference" }, I: GetWebsiteUserPreferenceReq, O: GetWebsiteUserPreferenceResp },
    { name: "UpdateUserPreference", options: { "api.post": "/api/website/user/preference/update" }, I: UpdateUserPreferenceReq, O: UpdateUserPreferenceResp },
    { name: "GetWebsiteMetricCount", options: { "api.post": "/api/website/metric/count" }, I: GetWebsiteMetricCountReq, O: GetWebsiteMetricCountResp },
    { name: "EvalWebsite", options: { "api.post": "/api/website/eval" }, I: EvalWebsiteReq, O: EvalWebsiteResp }
]);

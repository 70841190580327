// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "web_api/user_message.proto" (package "user_message", syntax proto3)
// tslint:disable
import { Empty } from "../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { UserMessage } from "./dto";
import { ResponseStatus } from "../base";
import { Page } from "../base";
/**
 * @generated from protobuf message user_message.GetMsgListReq
 */
export interface GetMsgListReq {
    /**
     * @generated from protobuf field: base.Page page = 1;
     */
    page?: Page;
}
/**
 * @generated from protobuf message user_message.GetMsgListResp
 */
export interface GetMsgListResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: user_message.GetMsgListRespData data = 2;
     */
    data?: GetMsgListRespData;
}
/**
 * @generated from protobuf message user_message.GetMsgListRespData
 */
export interface GetMsgListRespData {
    /**
     * @generated from protobuf field: repeated dto.UserMessage messages = 1;
     */
    messages: UserMessage[];
    /**
     * @generated from protobuf field: int32 total = 2;
     */
    total: number;
}
/**
 * @generated from protobuf message user_message.AddUserMessageResp
 */
export interface AddUserMessageResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message user_message.ReadUserMessageReq
 */
export interface ReadUserMessageReq {
    /**
     * @generated from protobuf field: int32 message_id = 1;
     */
    messageId: number;
}
/**
 * @generated from protobuf message user_message.ReadUserMessageResp
 */
export interface ReadUserMessageResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message user_message.DeleteUserMessageReq
 */
export interface DeleteUserMessageReq {
    /**
     * @generated from protobuf field: int32 message_id = 1;
     */
    messageId: number;
}
/**
 * @generated from protobuf message user_message.DeleteUserMessageResp
 */
export interface DeleteUserMessageResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetMsgListReq$Type extends MessageType<GetMsgListReq> {
    constructor() {
        super("user_message.GetMsgListReq", [
            { no: 1, name: "page", kind: "message", T: () => Page }
        ]);
    }
    create(value?: PartialMessage<GetMsgListReq>): GetMsgListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMsgListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMsgListReq): GetMsgListReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.Page page */ 1:
                    message.page = Page.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMsgListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.Page page = 1; */
        if (message.page)
            Page.internalBinaryWrite(message.page, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user_message.GetMsgListReq
 */
export const GetMsgListReq = new GetMsgListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMsgListResp$Type extends MessageType<GetMsgListResp> {
    constructor() {
        super("user_message.GetMsgListResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "data", kind: "message", T: () => GetMsgListRespData }
        ]);
    }
    create(value?: PartialMessage<GetMsgListResp>): GetMsgListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMsgListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMsgListResp): GetMsgListResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* user_message.GetMsgListRespData data */ 2:
                    message.data = GetMsgListRespData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMsgListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* user_message.GetMsgListRespData data = 2; */
        if (message.data)
            GetMsgListRespData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user_message.GetMsgListResp
 */
export const GetMsgListResp = new GetMsgListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMsgListRespData$Type extends MessageType<GetMsgListRespData> {
    constructor() {
        super("user_message.GetMsgListRespData", [
            { no: 1, name: "messages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserMessage },
            { no: 2, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetMsgListRespData>): GetMsgListRespData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messages = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMsgListRespData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMsgListRespData): GetMsgListRespData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated dto.UserMessage messages */ 1:
                    message.messages.push(UserMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total */ 2:
                    message.total = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMsgListRespData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated dto.UserMessage messages = 1; */
        for (let i = 0; i < message.messages.length; i++)
            UserMessage.internalBinaryWrite(message.messages[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).int32(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user_message.GetMsgListRespData
 */
export const GetMsgListRespData = new GetMsgListRespData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddUserMessageResp$Type extends MessageType<AddUserMessageResp> {
    constructor() {
        super("user_message.AddUserMessageResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<AddUserMessageResp>): AddUserMessageResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AddUserMessageResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddUserMessageResp): AddUserMessageResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddUserMessageResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user_message.AddUserMessageResp
 */
export const AddUserMessageResp = new AddUserMessageResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadUserMessageReq$Type extends MessageType<ReadUserMessageReq> {
    constructor() {
        super("user_message.ReadUserMessageReq", [
            { no: 1, name: "message_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ReadUserMessageReq>): ReadUserMessageReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageId = 0;
        if (value !== undefined)
            reflectionMergePartial<ReadUserMessageReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReadUserMessageReq): ReadUserMessageReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 message_id */ 1:
                    message.messageId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReadUserMessageReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 message_id = 1; */
        if (message.messageId !== 0)
            writer.tag(1, WireType.Varint).int32(message.messageId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user_message.ReadUserMessageReq
 */
export const ReadUserMessageReq = new ReadUserMessageReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadUserMessageResp$Type extends MessageType<ReadUserMessageResp> {
    constructor() {
        super("user_message.ReadUserMessageResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<ReadUserMessageResp>): ReadUserMessageResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ReadUserMessageResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReadUserMessageResp): ReadUserMessageResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReadUserMessageResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user_message.ReadUserMessageResp
 */
export const ReadUserMessageResp = new ReadUserMessageResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteUserMessageReq$Type extends MessageType<DeleteUserMessageReq> {
    constructor() {
        super("user_message.DeleteUserMessageReq", [
            { no: 1, name: "message_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteUserMessageReq>): DeleteUserMessageReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageId = 0;
        if (value !== undefined)
            reflectionMergePartial<DeleteUserMessageReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteUserMessageReq): DeleteUserMessageReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 message_id */ 1:
                    message.messageId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteUserMessageReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 message_id = 1; */
        if (message.messageId !== 0)
            writer.tag(1, WireType.Varint).int32(message.messageId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user_message.DeleteUserMessageReq
 */
export const DeleteUserMessageReq = new DeleteUserMessageReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteUserMessageResp$Type extends MessageType<DeleteUserMessageResp> {
    constructor() {
        super("user_message.DeleteUserMessageResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<DeleteUserMessageResp>): DeleteUserMessageResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteUserMessageResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteUserMessageResp): DeleteUserMessageResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteUserMessageResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user_message.DeleteUserMessageResp
 */
export const DeleteUserMessageResp = new DeleteUserMessageResp$Type();
/**
 * @generated ServiceType for protobuf service user_message.MsgService
 */
export const MsgService = new ServiceType("user_message.MsgService", [
    { name: "GetUserAllMessageList", options: { "api.post": "/api/user-message/all" }, I: Empty, O: GetMsgListResp },
    { name: "GetUserMessageList", options: { "api.post": "/api/user-message/page" }, I: GetMsgListReq, O: GetMsgListResp },
    { name: "AddUserMessage", options: { "api.post": "/user-message/add" }, I: UserMessage, O: AddUserMessageResp },
    { name: "ReadUserMessage", options: { "api.post": "/api/user-message/read" }, I: ReadUserMessageReq, O: ReadUserMessageResp },
    { name: "DeleteUserMessage", options: { "api.post": "/api/user-message/delete" }, I: DeleteUserMessageReq, O: DeleteUserMessageResp }
]);

// Generated code - do not modify
// @ts-nocheck
import { productionClient, localClient, Transport } from '@/utils/api/go_client'

import * as website_service from './website_service'

export class WebsiteServiceRealClient {
  constructor(private transport: Transport) {}

  async GetWebsiteList(
    request: website_service.GetWebsiteListReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<website_service.GetWebsiteListResp> {
    return this.transport.request({
      path: '/api/website/list',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetRelativeWebsiteList(
    request: website_service.GetRelativeWebsiteReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<website_service.GetRelativeWebsiteResp> {
    return this.transport.request({
      path: '/api/website/list/relative',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetWebsiteUserPreference(
    request: website_service.GetWebsiteUserPreferenceReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<website_service.GetWebsiteUserPreferenceResp> {
    return this.transport.request({
      path: '/api/website/user/preference',
      method: 'POST',
      input: request,
      options
    })
  }


  async UpdateUserPreference(
    request: website_service.UpdateUserPreferenceReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<website_service.UpdateUserPreferenceResp> {
    return this.transport.request({
      path: '/api/website/user/preference/update',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetWebsiteMetricCount(
    request: website_service.GetWebsiteMetricCountReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<website_service.GetWebsiteMetricCountResp> {
    return this.transport.request({
      path: '/api/website/metric/count',
      method: 'POST',
      input: request,
      options
    })
  }


  async EvalWebsite(
    request: website_service.EvalWebsiteReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<website_service.EvalWebsiteResp> {
    return this.transport.request({
      path: '/api/website/eval',
      method: 'POST',
      input: request,
      options
    })
  }
}

export const goWebsiteService = new WebsiteServiceRealClient(productionClient)

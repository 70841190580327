// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "web_api/api_def.proto" (package "web", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { PreferenceService } from "./api_def";
import type { UserCollectListResp } from "./api_def";
import type { UserCollectListReq } from "./api_def";
import type { GetUserPreferenceResp } from "./api_def";
import type { GetUserPreferenceReq } from "./api_def";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { UserPreferenceResp } from "./api_def";
import type { UserPreferenceReq } from "./api_def";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service web.PreferenceService
 */
export interface IPreferenceServiceClient {
    /**
     * 点赞/点踩/收藏和取消点赞/点踩/收藏
     *
     * @generated from protobuf rpc: UserPreference(web.UserPreferenceReq) returns (web.UserPreferenceResp);
     */
    userPreference(input: UserPreferenceReq, options?: RpcOptions): UnaryCall<UserPreferenceReq, UserPreferenceResp>;
    /**
     * 作品点赞/点踩/收藏数量
     *
     * @generated from protobuf rpc: GetUserPreference(web.GetUserPreferenceReq) returns (web.GetUserPreferenceResp);
     */
    getUserPreference(input: GetUserPreferenceReq, options?: RpcOptions): UnaryCall<GetUserPreferenceReq, GetUserPreferenceResp>;
    /**
     * 用户收藏列表
     *
     * @generated from protobuf rpc: UserCollectList(web.UserCollectListReq) returns (web.UserCollectListResp);
     */
    userCollectList(input: UserCollectListReq, options?: RpcOptions): UnaryCall<UserCollectListReq, UserCollectListResp>;
}
/**
 * @generated from protobuf service web.PreferenceService
 */
export class PreferenceServiceClient implements IPreferenceServiceClient, ServiceInfo {
    typeName = PreferenceService.typeName;
    methods = PreferenceService.methods;
    options = PreferenceService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * 点赞/点踩/收藏和取消点赞/点踩/收藏
     *
     * @generated from protobuf rpc: UserPreference(web.UserPreferenceReq) returns (web.UserPreferenceResp);
     */
    userPreference(input: UserPreferenceReq, options?: RpcOptions): UnaryCall<UserPreferenceReq, UserPreferenceResp> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserPreferenceReq, UserPreferenceResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 作品点赞/点踩/收藏数量
     *
     * @generated from protobuf rpc: GetUserPreference(web.GetUserPreferenceReq) returns (web.GetUserPreferenceResp);
     */
    getUserPreference(input: GetUserPreferenceReq, options?: RpcOptions): UnaryCall<GetUserPreferenceReq, GetUserPreferenceResp> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserPreferenceReq, GetUserPreferenceResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 用户收藏列表
     *
     * @generated from protobuf rpc: UserCollectList(web.UserCollectListReq) returns (web.UserCollectListResp);
     */
    userCollectList(input: UserCollectListReq, options?: RpcOptions): UnaryCall<UserCollectListReq, UserCollectListResp> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserCollectListReq, UserCollectListResp>("unary", this._transport, method, opt, input);
    }
}
